import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(
    (theme) => ({
        root: {
            padding: 0,
            display: 'flex'
        },
        icon: {
            marginRight: theme.spacing(2),
            color: theme.palette.primary.contrastText,
            fontSize: 32
        },
        language: {
            display: 'flex',
            flex: 1,
            justifyContent: 'flex-end'
        },
        languageName: {
            color: theme.palette.common.white
        },
        iconFlag: {
            borderBlockColor: theme.palette.common.white,
            borderWidth: 1,
            borderStyle: 'solid',
            borderRadius: 50,
            marginRight: 6
        },
        languages: {
            display: 'flex',
            flexDirection: 'column',
            padding: 8,
            alignItems: 'flex-start'
        }
    })
  );

  export default useStyles;

