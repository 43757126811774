import * as React from 'react';
import {Link} from 'react-router-dom';
import {useStyles} from './styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { useTranslation } from 'react-i18next';
import MediaCard from '../components/media-card';
import Button from '@material-ui/core/Button';
import Texti18n from '../components/Texti18n';

import IMGTrnaslates from '../images/sello.jpg';
import IMGTransGen from '../images/genericas.png';
import IMGInter from '../images/interpretaciones.jpg';

export const TranslatePage: React.FC<{}> = () => {
    const styles = useStyles();
    const {t} = useTranslation();
    return (
        <div className={styles.container}>
            <div className={styles.titleBlock}>
                <Typography
                    variant='h5'
                    className={styles.titleText}>
                    <Texti18n langKey='translate-page.title'/>
                </Typography>
            </div>
            <Typography
                variant='h5'
                className={styles.subtitleText}>
                {t('translate-page.subtitle')}
            </Typography>
            <div className={styles.cardSection}>
                <MediaCard
                    image={IMGTrnaslates}
                    imageAlt='sello de traductor jurado'
                    title={'translate-page.title-sworm'}
                    description={'translate-page.details-sworm'}
                    actionText={t('translate-page.ask-quote')}
                    actionRoute='contact'/>
                <MediaCard
                    image={IMGInter}
                    imageAlt=''
                    title={'translate-page.title-interpretation'}
                    description={'translate-page.details-interpretation'}
                    actionText={t('translate-page.ask-quote')}
                    actionRoute='contact'/>
                <MediaCard
                    image={IMGTransGen}
                    imageAlt=''
                    title={'translate-page.title-generics'}
                    description={'translate-page.details-generics'}
                    actionText={t('translate-page.ask-quote')}
                    actionRoute='contact'/>
            </div>
            <Divider/>
            <div
                className={styles.portraitSection}
                aria-label='home page'>
                <Typography
                    variant='h5'
                    className={styles.portraitTitle}>
                    <Texti18n langKey='translate-page.title-sworm'/>
                </Typography>
                <Typography
                    variant='h5'
                    className={styles.portraitText}>
                    <Texti18n langKey='translate-page.long-sworm'/>
                </Typography>
                <Button size='small' color='primary' component={Link} to={'contact'}>{t('translate-page.ask-quote')}</Button>
            </div>
            <div
                className={styles.portraitSection}
                aria-label='home page'>
                <Typography
                    variant='h5'
                    className={styles.portraitTitle}>
                    <Texti18n langKey='translate-page.title-interpretation'/>
                </Typography>
                <Typography
                    variant='h5'
                    className={styles.portraitText}>
                        <Texti18n langKey='translate-page.long-interpretation'/>
                </Typography>
                <Button size='small' color='primary' component={Link} to={'contact'}>{t('translate-page.ask-quote')}</Button>
            </div>
{/*            <div
                className={styles.portraitSection}
                aria-label='home page'>
                <Typography
                    variant='h5'
                    className={styles.portraitTitle}>
                    <Texti18n langKey='translate-page.title-generics'/>
                </Typography>
                <Typography
                    variant='h5'
                    className={styles.portraitText}>
                        <Texti18n langKey='translate-page.long-generics'/>
                </Typography>
                <Button size='small' color='primary' component={Link} to={'contact'}>{t('translate-page.ask-quote')}</Button>
            </div>
*/}
        </div>
    );
};

export default TranslatePage;