import {makeStyles} from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        paddingTop: 70,
    },
    titleBlock: {
        display: 'flex',
        padding: 20,
        justifyContent: 'center'
    },
    titleText: {
        fontFamily: 'italic',
        fontSize: 36
    },
    subtitleText: {
        display: 'flex',
        justifyContent: 'center',
        fontSize: 16,
        fontWeight: 700,
        paddingTop: 16,
        marginLeft: 24,
        marginRight: 16,
        marginBottom: 24
    },
    channels: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        marginBottom: 30,
    },
    channel: {
        display: 'flex',
        width: 325,
        flexDirection: 'column',
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: theme.palette.primary.main,
        borderRadius: 12,
        margin: 12,
        textDecoration: 'none',
        color: theme.palette.common.black
    },
    channelHeader: {
        display: 'flex',
        padding: 6
    },
    channelIcon: {
        padding: 8
    },
    channelValue: {
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: 24,
        fontWeight: 700
    },
    channelMessage: {
        display: 'flex',
        flex: 1,
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,
        borderBottomLeftRadius: 12,
        borderBottomRightRadius: 12,
        padding: 6,
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center'
    },
    whatsapp: {
        color: '#075E54'
    },
    facebook: {
        color: '#3b5998'
    },
    twitter: {
        color: '#00acee'
    },
    email: {
        color: '#c71610'
    }
}));

export default useStyles;