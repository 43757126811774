import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(
    (theme) => ({
        container: {
            marginTop: 75,
            width: 290
        },
        selected: {
            color: theme.palette.primary.main
        },
        unselected: {
            color: theme.palette.primary.dark
        },
        avatar: {
            width: '200px',
            height: '200px',
            margin: 16
        },
        social: {
            display: 'flex',
            justifyContent: 'center',
            paddingTop: 12
        },
        facebook: {
            color: '#3b5998'
        },
        twitter: {
            color: '#00acee'
        },
        instagram: {
            color: '#c32aa3'
        }
    })
);

 export default useStyles;