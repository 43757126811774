import * as React from 'react';
import { useTranslation } from 'react-i18next';

export const Texti18n: React.FC<{langKey: string}> = ({langKey}) => {
    const {t} = useTranslation();
    return (
        <span dangerouslySetInnerHTML={{__html: t(langKey)}}/>
    );
};

export default Texti18n;