import * as React from 'react';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import FBIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import Instagram from '@material-ui/icons/Instagram';
import HomeIcon from '@material-ui/icons/EmojiPeople';
import TransIcon from '@material-ui/icons/Translate';
import TourIcon from '@material-ui/icons/PersonPin';
import LessonIcon from '@material-ui/icons/LocalLibrary';
import ContactIcon from '@material-ui/icons/ContactMail';
import IconButton from '@material-ui/core/IconButton';
import Avatar from '@material-ui/core/Avatar'
import Divider from '@material-ui/core/Divider';
import {Link, useLocation} from 'react-router-dom';
import {
    useMainMenuState,
    useMainMenuToggle
} from '../main-menu-provider';
import IMGLogo from '../../images/perfil.jpg'
import { useTranslation } from 'react-i18next';
import {useStyles} from './styles';
import { Location } from 'history';

import IMGMinisterio from '../../images/logoministerio.jpg';

const isSelected = (path: string, location: Location) => {
    return path === location.pathname;
}

export const MenuDrawer: React.FC<{}> = () => {
    const styles = useStyles();
    const location = useLocation();
    const isOpen = useMainMenuState();
    const {close} = useMainMenuToggle();
    const {t} = useTranslation();
    return (
        <Drawer
            aria-label='drawer menu'
            open={isOpen}
            onClose={close}>
            <List
                className={styles.container}
                onClick={close}>
                <ListItem alignItems='center'>
                    <Avatar alt='logo dmmweb' src={IMGLogo} className={styles.avatar}/>
                </ListItem>
                <ListItem
                    button
                    component={Link}
                    to='/'
                    aria-label='menu home page'
                    selected={isSelected('/', location)}
                    className={isSelected('/', location) ? styles.selected : styles.unselected}>
                    <ListItemIcon><HomeIcon color={isSelected('/', location) ? 'primary' : 'inherit'}/></ListItemIcon>
                    <ListItemText primary={t('drawer.home')}/>
                </ListItem>
                <Divider/>
                <ListItem className={styles.unselected}>
                    <ListItemText primary={t('drawer.services')} color='primary'/>
                </ListItem>
                <ListItem
                    button
                    component={Link}
                    to='/translate'
                    aria-label='menu translate page'
                    selected={isSelected('/translate', location)}
                    className={isSelected('/translate', location) ? styles.selected : styles.unselected}>
                    <ListItemIcon><TransIcon color={isSelected('/translate', location) ? 'primary' : 'inherit'}/></ListItemIcon>
                    <ListItemText primary={t('drawer.translate')}/>
                </ListItem>
                <ListItem
                    button
                    component={Link}
                    to='/tourguide'
                    aria-label='menu tourguide page'
                    selected={isSelected('/tourguide', location)}
                    className={isSelected('/tourguide', location) ? styles.selected : styles.unselected}>
                    <ListItemIcon><TourIcon color={isSelected('/tourguide', location) ? 'primary' : 'inherit'}/></ListItemIcon>
                    <ListItemText primary={t('drawer.tourguide')}/>
                </ListItem>
                <ListItem
                    button
                    component={Link}
                    to='/lessons'
                    aria-label='menu lessons page'
                    selected={isSelected('/lessons', location)}
                    className={isSelected('/lessons', location) ? styles.selected : styles.unselected}>
                    <ListItemIcon><LessonIcon color={isSelected('/lessons', location) ? 'primary' : 'inherit'}/></ListItemIcon>
                    <ListItemText primary={t('drawer.lessons')}/>
                </ListItem>
                <Divider/>
                <ListItem
                    button
                    component={Link}
                    to='/contact'
                    aria-label='menu contact page'
                    selected={isSelected('/contact', location)}
                    className={isSelected('/contact', location) ? styles.selected : styles.unselected}>
                    <ListItemIcon><ContactIcon color={isSelected('/contact', location) ? 'primary' : 'inherit'}/></ListItemIcon>
                    <ListItemText primary={t('drawer.contact')}/>
                </ListItem>
                <ListItem className={styles.social}>
                    <IconButton onClick={() => window.open('https://www.facebook.com/Danieltraductoryguia','_blank')}><FBIcon className={styles.facebook}/></IconButton>
                    <IconButton onClick={() => window.open('https://twitter.com/tradujurado','_blank')}><TwitterIcon className={styles.twitter}/></IconButton>
                    <IconButton onClick={() => window.open('https://instagram.com','_blank')}><Instagram className={styles.instagram}/></IconButton>
                </ListItem>
                <ListItem style={{display: 'flex', justifyContent: 'center', marginTop: 30}}>
                    <img src={IMGMinisterio} alt='logo ministerio'/>
                </ListItem>
            </List>
        </Drawer>
    );
};

export default MenuDrawer;
