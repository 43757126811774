import * as React from 'react';
import {useStyles} from './styles';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import MediaCard from '../components/media-card';

import IMGTrnaslates from '../images/sello.jpg';
import IMGTourGuide from '../images/crucerocartagena.jpg';
import IMGLessons from '../images/lessons.png';


export const HomePage: React.FC<{}> = () => {
    const styles = useStyles();
    const {t} = useTranslation();
    return (
        <div className={styles.container}>
            <div className={styles.titleBlock}>
                <Typography
                    variant='h5'
                    className={styles.titleText}>
                    {t('home-page.title')}
                </Typography>
            </div>
            <Typography
                variant='h5'
                className={styles.subtitleText}>
                {t('home-page.subtitle')}
            </Typography>
            <div className={styles.cardSection}>
                <MediaCard
                    to='translate'
                    image={IMGTrnaslates}
                    imageAlt='sello de traductor jurado'
                    title={'home-page.title-translate'}
                    description={'home-page.details-translate'}
                    actionText={t('home-page.ask-quote')}
                    actionRoute='contact'/>
                <MediaCard
                    to='tourguide'
                    image={IMGTourGuide}
                    imageAlt=''
                    title={'home-page.title-tourguide'}
                    description={'home-page.details-tourguide'}
                    actionText={t('home-page.ask-quote')}
                    actionRoute='contact'/>
                <MediaCard
                    to='lessons'
                    image={IMGLessons}
                    imageAlt=''
                    title={'home-page.title-lessons'}
                    description={'home-page.details-lessons'}
                    actionText={t('home-page.ask-quote')}
                    actionRoute='contact'/>
            </div>

        </div>
    );
};

export default HomePage;

/*
            <Divider/>
            <div
                className={styles.portraitSection}
                aria-label='home page'>
                <Typography
                    variant='h5'
                    className={styles.portraitTitle}>
                    {t('home-page.whoami')}
                </Typography>
                <img src={IMGMe} alt='me doing my job' className={styles.portraitImage}/>
                <Typography
                    variant='h5'
                    className={styles.portraitText}>
                    {t('home-page.whymywork')}
                </Typography>
            </div>
*/