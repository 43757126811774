import {makeStyles} from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    card: {
        margin: 16,
        width: 355,
    },
    cardImage: {
        height: 140,
    },
    actions: {
        display: 'flex',
        flex: 1,
        aligItems: 'flex-end'
    }
}));

export default useStyles;