import * as React from 'react';
import {useStyles} from './styles';
import {useHistory, Link} from 'react-router-dom';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Texti18n from '../Texti18n';

export const SecondAction: React.FC<{
    actionText?: string;
    actionRoute?: string;
}> = ({actionText, actionRoute}) => {
    const styles = useStyles();
    if (!actionText || !actionRoute) return null;

    return (
        <div className={styles.actions}>
            <CardActions>
                <Button size='small' color='primary' component={Link} to={actionRoute}>
                    {actionText}
                </Button>
            </CardActions>
        </div>
    );
};

export type MediaCardProps = {
    to?: string;
    image: string;
    imageAlt: string;
    title: string;
    description: string;
    actionText?: string;
    actionRoute?: string;
};
export const MediaCard: React.FC<MediaCardProps> = ({to, image, imageAlt, title, description, actionText, actionRoute}) => {
    const styles = useStyles();
    const history = useHistory();

    if (!to || typeof to !== 'string') {
        return (
            <Card className={styles.card}>
                <CardMedia
                    className={styles.cardImage}
                    image={image}
                    title={imageAlt}
                    />
                <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                        <Texti18n langKey={title}/>
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                        <Texti18n langKey={description}/>
                    </Typography>
                </CardContent>
                <SecondAction actionText={actionText} actionRoute={actionRoute}/>
            </Card>
        );
    }
    return (
        <Card className={styles.card}>
            <CardActionArea onClick={() => history.push(to)}>
                <CardMedia
                    className={styles.cardImage}
                    image={image}
                    title={imageAlt}
                    />
                <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                        <Texti18n langKey={title}/>
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                        <Texti18n langKey={description}/>
                    </Typography>
                </CardContent>
            </CardActionArea>
            <SecondAction actionText={actionText} actionRoute={actionRoute}/>
        </Card>
    );
};

export default MediaCard;
