import * as React from 'react';
import Typography from '@material-ui/core/Typography';
import FBIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import EmailIcon from '@material-ui/icons/AlternateEmail';
import Texti18n from '../components/Texti18n';

import {useStyles} from './styles';

export const ContactPage: React.FC<{}> = () => {
    const styles = useStyles();
    return (
        <div className={styles.container}>
            <div className={styles.titleBlock}>
                <Typography
                    variant='h5'
                    className={styles.titleText}>
                    <Texti18n langKey='contact-page.title'/>
                </Typography>
            </div>
            <Typography
                variant='h5'
                className={styles.subtitleText}>
                <Texti18n langKey='contact-page.subtitle'/>
            </Typography>
            <div className={styles.channels}>
                <a  href='tel:+34654456670' target='blank' className={styles.channel}>
                    <div className={styles.channelHeader}>
                        <div className={styles.channelIcon}><WhatsAppIcon fontSize='large' className={styles.whatsapp}/></div>
                        <div className={styles.channelValue}>654 45 66 70</div>
                    </div>
                    <div className={styles.channelMessage}>
                        <Texti18n langKey='contact-page.phone'/>
                    </div>
                </a>
                <a href='mailto:info@danimegias.es' target='blank' className={styles.channel}>
                    <div className={styles.channelHeader}>
                        <div className={styles.channelIcon}><EmailIcon fontSize='large' className={styles.email}/></div>
                        <div className={styles.channelValue}>info@danimegias.es</div>
                    </div>
                    <div className={styles.channelMessage}>
                        <Texti18n langKey='contact-page.email'/>
                    </div>
                </a>
                <a href='https://www.facebook.com/Danieltraductoryguia' target='blank' className={styles.channel}>
                    <div className={styles.channelHeader}>
                        <div className={styles.channelIcon}><FBIcon fontSize='large' className={styles.facebook}/></div>
                        <div className={styles.channelValue}>@Danieltraductoryguia</div>
                    </div>
                    <div className={styles.channelMessage}>
                        <Texti18n langKey='contact-page.facebook'/>
                    </div>
                </a>
                <a href='https://twitter.com/messages/compose?recipient_id=752270238121680897' target='blank' className={styles.channel}>
                    <div className={styles.channelHeader}>
                        <div className={styles.channelIcon}><TwitterIcon fontSize='large' className={styles.twitter}/></div>
                        <div className={styles.channelValue}>@tradujurado</div>
                    </div>
                    <div className={styles.channelMessage}>
                        <Texti18n langKey='contact-page.twitter'/>
                    </div>
                </a>
            </div>
        </div>
    );
};

export default ContactPage;
