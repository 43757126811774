import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import HomeIcon from '@material-ui/icons/Menu';
import Popover from '@material-ui/core/Popover';
import {useMainMenuToggle} from '../main-menu-provider';
import { useTranslation } from 'react-i18next';
import { useStyles } from './styles';

import IMGES from '../../images/es.png';
import IMGUK from '../../images/uk.png';
import IMGDE from '../../images/de.png';

export const loadFlagFromLanguage = (lang = 'en') => {
    switch(lang.substr(0, 2)) {
        case 'es': return {img: IMGES, name: 'language.es'};
        case 'de': return {img: IMGDE, name: 'language.de'};;
        default: return {img: IMGUK, name: 'language.en'};;
    }
};

export type TitleBarProps = {};
export const TitleBar: React.FC<TitleBarProps> = function TitleBar() {
    const styles = useStyles();
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const {toggle} = useMainMenuToggle();
    const { t, i18n } = useTranslation();
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
      };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const lang = loadFlagFromLanguage(i18n.language);
    const changeLanguage = (lang: string) => {
        i18n.changeLanguage(lang);
        handleClose();
    }
    return (
        <AppBar position='fixed' className={styles.root}>
            <Toolbar>
                <IconButton aria-label='toggle show menu' onClick={toggle}>
                    <HomeIcon className={styles.icon} fontSize='large'/>
                </IconButton>
                <Typography variant='h6' color='inherit' noWrap>
                    {t('toolbar.title')}
                </Typography>
                <div className={styles.language}>
                    <Button onClick={handleClick} className={styles.languageName}>
                        <img className={styles.iconFlag} alt='language flag' src={lang.img} height={22}/>
                        {t(lang.name)}
                    </Button>
                </div>
                <Popover
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    style={{zIndex: 999999}}
                >
                    <div className={styles.languages}>
                        <Button onClick={() => changeLanguage('es')}>
                            <img className={styles.icon} alt='language flag es' src={IMGES} height={24}/>
                            <Typography>{t('language.es')}</Typography>
                        </Button>
                        <Button onClick={() => changeLanguage('en')}>
                            <img className={styles.icon} alt='language flag en' src={IMGUK} height={24}/>
                            <Typography>{t('language.en')}</Typography>
                        </Button>
                        {/*<Button onClick={() => changeLanguage('de')}>
                            <img className={styles.icon} alt='language flag de' src={IMGDE} height={24}/>
                            <Typography>{t('language.de')}</Typography>
                        </Button>*/}
                    </div>
                </Popover>
            </Toolbar>
        </AppBar>
    );
}

export default TitleBar;