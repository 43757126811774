import React from 'react';
import {Switch, Route} from 'react-router-dom';
import { useStyles } from './styles';
import TitleBar from './title-bar';
import MenuDrawer from './menu-drawer';
import HomePage from '../home';
import TranslatePage from '../translate';
import TourguidePage from '../tourguide';
import LessonsPage from '../lessons';
import ContactPage from '../contact';
import NotFoundPage from '../not-found';

export const App: React.FC<{}> = function App() {
    const styles = useStyles();

    // const [pwd, setPwd] = React.useState('');
    // if (pwd !== 'daniweb2020') {
    //     return (
    //         <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh'}}>
    //             Password: <input type='password' onChange={(ev) => setPwd(ev.target.value)}/>
    //         </div>
    //     );
    // }

    return (
        <div className={styles.root}>
            <React.Suspense fallback='Loading...'>
                <TitleBar/>
                <MenuDrawer/>
                <Switch>
                    <Route exact path='/'>
                        <HomePage/>
                    </Route>
                    <Route path='/translate'>
                        <TranslatePage/>
                    </Route>
                    <Route path='/tourguide'>
                        <TourguidePage/>
                    </Route>
                    <Route path='/lessons'>
                        <LessonsPage/>
                    </Route>
                    <Route path='/contact'>
                        <ContactPage/>
                    </Route>
                    <Route>
                        <NotFoundPage/>
                    </Route>
                </Switch>
            </React.Suspense>
        </div>
    );
}

export default App;

