import * as React from 'react';
import {useStyles} from './styles';
import { Typography } from '@material-ui/core';
import Texti18n from '../components/Texti18n';

export const NotFoundPage: React.FC<{}> = () => {
    const styles = useStyles();
    return (
        <div className={styles.container}>
            <div aria-label='page not found'>
                <Typography
                    variant='h3'
                    color='primary'>
                    <Texti18n langKey='notfound-page.title'/>
                </Typography>
            </div>
        </div>
    );
};

export default NotFoundPage;
