import {makeStyles} from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        paddingTop: 70,
    },
    titleBlock: {
        display: 'flex',
        padding: 20,
        justifyContent: 'center'
    },
    titleText: {
        fontFamily: 'italic',
        fontSize: 36
    },
    subtitleText: {
        display: 'flex',
        justifyContent: 'center',
        fontSize: 16,
        fontWeight: 700,
        paddingTop: 16,
        marginLeft: 24,
        marginBottom: 24,
        marginRight: 16
    },
    portraitSection: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        marginTop: 40
    },
    portraitImage: {
        width: '70%'
    },
    portraitTitle: {
        fontSize: 24,
        marginLeft: 16,
        fontWeight: 800
    },
    portraitText: {
        fontSize: 20,
        marginLeft: 24,
        padding: 16
    }
}));

export default useStyles;