import * as React from 'react';
import Typography from '@material-ui/core/Typography';
import {Link} from 'react-router-dom';
import Divider from '@material-ui/core/Divider';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import Texti18n from '../components/Texti18n';

import {useStyles} from './styles';

const Waypath = [
    ['tourguide-page.img001', require('../images/tourguide/img001.jpg')],
    ['tourguide-page.img002', require('../images/tourguide/img002.jpg')],
    ['tourguide-page.img009', require('../images/tourguide/img009.jpg')],
    ['tourguide-page.img003', require('../images/tourguide/img003.jpg')],
    // ['tourguide-page.img004', require('../images/tourguide/img004.jpg')],
    ['tourguide-page.img005', require('../images/tourguide/img005.jpg')],
    ['tourguide-page.img006', require('../images/tourguide/img006.jpg')],
    ['tourguide-page.img007', require('../images/tourguide/img007.jpg')],
    ['tourguide-page.img008', require('../images/tourguide/img008.jpg')],
    ['tourguide-page.img010', require('../images/tourguide/img010.jpg')],
    ['tourguide-page.img011', require('../images/tourguide/img011.jpg')],
    ['tourguide-page.img012', require('../images/tourguide/img012.jpg')],
    ['tourguide-page.img013', require('../images/tourguide/img013.jpg')],
    ['tourguide-page.img014', require('../images/tourguide/img014.jpg')],
    ['tourguide-page.img015', require('../images/tourguide/img015.jpg')],
    ['tourguide-page.img016', require('../images/tourguide/img016.jpg')],
    ['tourguide-page.img017', require('../images/tourguide/img017.jpg')],
    ['tourguide-page.img018', require('../images/tourguide/img018.jpg')],
    ['tourguide-page.img019', require('../images/tourguide/img019.jpg')],
    ['tourguide-page.img020', require('../images/tourguide/img020.jpg')],
    ['tourguide-page.img021', require('../images/tourguide/img021.jpg')],
    ['tourguide-page.img022', require('../images/tourguide/img022.jpg')],
    ['tourguide-page.img023', require('../images/tourguide/img023.jpg')],
    ['tourguide-page.img024', require('../images/tourguide/img024.jpg')],
];

export const TourguidePage: React.FC<{}> = () => {
    const styles = useStyles();
    const {t} = useTranslation();
    return (
        <div className={styles.container}>
            <div className={styles.titleBlock}>
                <Typography
                    variant='h5'
                    className={styles.titleText}>
                    <Texti18n langKey='tourguide-page.title'/>
                </Typography>
            </div>
            <Typography
                variant='h5'
                className={styles.subtitleText}>
                <Texti18n langKey='tourguide-page.subtitle'/>
            </Typography>
            <Divider/>
            <div
                className={styles.portraitSection}
                aria-label='home page'>
                <Typography
                    variant='h5'
                    className={styles.portraitTitle}>
                    <Texti18n langKey='tourguide-page.title'/>
                </Typography>
                <Typography
                    variant='h5'
                    className={styles.portraitText}>
                    <Texti18n langKey='tourguide-page.long-details'/>
                </Typography>
                <Button size='small' color='primary' component={Link} to={'contact'}>{t('tourguide-page.ask-quote')}</Button>
            </div>
            <div className={styles.sitesSection}>
            {
                Waypath.map(
                    (site) => (
                        <a
                            href={site[1]}
                            className={styles.siteSection}
                            aria-label='home page'>
                            <img src={site[1]} alt={site[0]} className={styles.siteImage}/>
                            <Typography
                                variant='h5'
                                className={styles.siteText}>
                                <Texti18n langKey={site[0]}/>
                            </Typography>
                        </a>
                    )
                )
            }
            </div>
            <Button size='small' color='primary' component={Link} to={'contact'}>{t('tourguide-page.ask-quote')}</Button>
            <div style={{height: 30}}/>
        </div>
    );
};

export default TourguidePage;
