import * as React from 'react';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import {Link} from 'react-router-dom';
import Texti18n from '../components/Texti18n';

import {useStyles} from './styles';

export const LessonsPage: React.FC<{}> = () => {
    const styles = useStyles();
    const {t} = useTranslation();
    return (
        <div className={styles.container}>
            <div className={styles.titleBlock}>
                <Typography
                    variant='h5'
                    className={styles.titleText}>
                    <Texti18n langKey='lessons-page.title'/>
                </Typography>
            </div>
            <Typography
                variant='h5'
                className={styles.subtitleText}>
                <Texti18n langKey='lessons-page.subtitle'/>
            </Typography>
            <Divider/>
            <div
                className={styles.portraitSection}
                aria-label='home page'>
                <Typography
                    variant='h5'
                    className={styles.portraitTitle}>
                    <Texti18n langKey='lessons-page.title'/>
                </Typography>
                <Typography
                    variant='h5'
                    className={styles.portraitText}>
                    <Texti18n langKey='lessons-page.long-details'/>
                </Typography>
                <Button size='small' color='primary' component={Link} to={'contact'}>{t('lessons-page.ask-quote')}</Button>
            </div>
        </div>
    );
};

export default LessonsPage;
